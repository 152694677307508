import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const MoveMenu = (props) => {
  const {
    displayItems,
    setDisplayItems,
    allFolders,
    moveLayer,
    appState,
    errorDisplay,
    setAppState,
    dispatch,
    LOADING_MESSAGE,
    moveFolder,
  } = props;
  return (
    <Menu
      onClick={(e) => e.preventDefault()}
      id="long-move-menu"
      anchorEl={displayItems.moveMenuDisplay}
      keepMounted
      style={{ height: 470 }}
      open={displayItems.moveMenuDisplay}
      onClose={() => {
        setDisplayItems({ ...displayItems, moveMenuDisplay: false });
      }}
    >
      <MenuItem disabled>
        <strong> Move to</strong>
      </MenuItem>
      {/* yaha krna hai */}
      {displayItems.menuType === "file" &&
        allFolders.map((folder) => {
          if (folder.id != appState.folderId)
            return (
              <MenuItem
                key={`Project#${folder.projectId}_${folder.id}`}
                onClick={() => {
                  dispatch({ type: LOADING_MESSAGE, isLoading: true });
                  moveLayer(appState.fileId, appState.folderId, folder.id).then(
                    (res) => {
                      if (res) {
                        if (res.data.status == 200) {
                          // *** verify it as i removed code
                          // handleCloseLayerMove;
                          errorDisplay("success", "Layer moved successfully");
                          setDisplayItems({
                            ...displayItems,
                            moveMenuDisplay: false,
                          });
                          let tempAppStateLayerArray = appState.layerArray;
                          let tempIndex = appState.layerArray.findIndex(
                            (a) => a.id === appState.fileId
                          );
                          tempAppStateLayerArray[tempIndex].folderId =
                            folder.id;
                          tempAppStateLayerArray[tempIndex].folder_id =
                            folder.id;
                          tempAppStateLayerArray[tempIndex].projectId =
                            folder.projectId;
                          setAppState({
                            ...appState,
                            layerArray: tempAppStateLayerArray,
                            reloadProjects: true,
                          });
                        }
                      } else {
                        errorDisplay("error", "Request Unsuccessful");
                        setDisplayItems({
                          ...displayItems,
                          moveMenuDisplay: false,
                        });
                      }
                    }
                  );
                }}
              >
                {`${
                  allFolders.filter(
                    (itemFolder) => itemFolder.id == folder.id
                  )[0].projectName
                } / ${
                  folder.parentId
                    ? allFolders.filter(
                        (itemFolder) => itemFolder.id == folder.parentId
                      )[0].name +
                      " / " +
                      folder.name
                    : folder.name
                }`}
              </MenuItem>
            );
        })}
      {displayItems.menuType === "project" &&
        appState.projects?.map((project, index) => {
          // if (project.id != appState.projectId) {
          return (
            <MenuItem
              key={`Layer#${project.id}_${index}`}
              onClick={() => {
                dispatch({ type: LOADING_MESSAGE, isLoading: true });
                console.log(
                  "folderId",
                  appState.folderId,
                  "projectId",
                  appState.projectId,
                  "updatedprojectId",
                  project.id
                );
                moveFolder(
                  appState.folderId,
                  appState.projectId,
                  false,
                  project.id
                ).then((res) => {
                  if (res) {
                    if (res.data.status == 200) {
                      // *** verify it i removed code
                      errorDisplay("success", "Folder moved successfully");
                      setDisplayItems({
                        ...displayItems,
                        moveMenuDisplay: false,
                      });
                      setAppState({
                        ...appState,
                        layerArray: appState.layerArray.map((itemLayer) => {
                          if (
                            itemLayer.projectId == appState.projectId &&
                            itemLayer.folderId == appState.folderId
                          ) {
                            itemLayer.projectId = project.id;
                          }
                          return itemLayer;
                        }),
                        reloadProjects: true,
                      });
                    }
                  } else {
                    errorDisplay("error", "Request Unsuccessful");
                  }
                });
              }}
            >
              {"Project-" + project.name}
            </MenuItem>
          );
          // }
        })}
      {displayItems.menuType === "project" &&
        allFolders.map((folder) => {
          if (folder.id != appState.folderId)
            return (
              <MenuItem
                key={`Project#${folder.projectId}_${folder.id}`}
                onClick={() => {
                  const updatedProjectId = folder.projectId;
                  console.log(
                    "folderId",
                    appState.folderId,
                    "projectId",
                    appState.projectId,
                    "updatedfolderId",
                    folder.id,
                    "updatedprojectId",
                    updatedProjectId
                  );

                  dispatch({ type: LOADING_MESSAGE, isLoading: true });
                  moveFolder(
                    appState.folderId,
                    appState.projectId,
                    folder.id,
                    updatedProjectId
                  ).then((res) => {
                    if (res) {
                      if (res.data.status == 200) {
                        // *** verify it as i removed code
                        // handleCloseLayerMove;
                        errorDisplay("success", "Layer moved successfully");
                        setDisplayItems({
                          ...displayItems,
                          moveMenuDisplay: false,
                        });
                        setAppState({ ...appState, reloadProjects: true });
                      }
                    } else {
                      errorDisplay("error", "Request Unsuccessful");
                      setDisplayItems({
                        ...displayItems,
                        moveMenuDisplay: false,
                      });
                    }
                  });
                }}
              >
                {`${
                  allFolders.filter(
                    (itemFolder) => itemFolder.id == folder.id
                  )[0].projectName
                } / ${
                  folder.parentId
                    ? allFolders.filter(
                        (itemFolder) => itemFolder.id == folder.parentId
                      )[0].name +
                      " / " +
                      folder.name
                    : folder.name
                }`}
              </MenuItem>
            );
        })}
    </Menu>
  );
};

export default MoveMenu;
