import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Input, Modal, Progress, Radio } from "antd";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import EditIcon from "@material-ui/icons/Edit";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ShareIcon from "@material-ui/icons/Share";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MapIcon from "@material-ui/icons/Map";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  downloadFolder,
  getSingleLayer,
} from "../../../../../services/user.service";
import { API_URL } from "../../../../common/common";
import secureLocalStorage from "react-secure-storage";
import { SET_MESSAGE } from "../../../../../actions/types";

const FileActionMenu = (props) => {
  const {
    actionState,
    anchorEl,
    setAnchorEl,
    setAppState,
    setDisplayItems,
    setFilePath,
    findPath,
    setSection,
    setCurrentSection,
    iconStyle,
    displayItems,
    setAddWMSDialog,
    dispatch,
    deleteFolder,
    updateLocalStorage,
    LOADING_MESSAGE,
    errorDisplay,
    authHeader,
    appState,
    deleteLayer,
    setRightDrawer,
    handleInfoDrawerClose,
    removeItemFromProject,
    setUploadFilesImagery,
    pushPendingDownload,
    viewer,
    //mapRef,
    pendingUpdate,
    uploadFilesImagery,
    fileAccordianOnDoubleClick,
    updateMapFeatures,
  } = props;
  const { project, item, anchorPosition } = actionState;

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      // getContentAnchorEl={null}
      style={anchorPosition}
      // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      // transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {project &&
        item &&
        (item.isFolder ? (
          <>
            {project.permission?.edit && (
              <>
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    setAnchorEl(null);
                    setAppState({
                      ...appState,
                      projectId: project.id,
                      folderId: item.id,
                    });
                    setDisplayItems({
                      ...displayItems,
                      name: "",
                      editType: "folder",
                      openActionDialog: false,
                      openFolderDialog: true,
                    });
                  }}
                >
                  <CreateNewFolderIcon style={iconStyle} />
                  Add
                </MenuItem>
                <MenuItem
                  disabled={
                    !JSON.parse(secureLocalStorage.getItem("restrictions"))
                      ?.shareLink
                  }
                  onClick={(event) => {
                    event.stopPropagation();
                    // if (
                    //   secureLocalStorage.getItem("organizationName") &&
                    //   secureLocalStorage.getItem("organizationName") !=
                    //     "null" &&
                    //   secureLocalStorage.getItem("organizationName") !=
                    //     "undefined"
                    // ) {
                    // } else {
                    //   dispatch({
                    //     type: SET_MESSAGE,
                    //     payload: {
                    //       message: "Please set up your team first!",
                    //       severity: "error",
                    //       isOpen: true,
                    //     },
                    //   });
                    //   setAnchorEl(null);
                    //   let element = document.getElementById("createHighlight");
                    //   element.classList.add("blink-Button");
                    //   setTimeout(() => {
                    //     element.classList.remove("blink-Button");
                    //   }, 5000);
                    //   return;
                    // }
                    setAppState({
                      ...appState,
                      projectId: project.id,
                      folderId: item.id,
                    });
                    setDisplayItems({
                      ...displayItems,
                      openSharingDialog: true,
                      name: project.name,
                      id: project.id,
                    });
                    setAnchorEl(null);
                  }}
                >
                  <ShareIcon style={iconStyle} />
                  Share
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    setAnchorEl(null);
                    setAppState({
                      ...appState,
                      projectId: project.id,
                      folderId: item.id,
                    });
                    setDisplayItems({
                      ...displayItems,
                      name: item.name,
                      editType: "folder",
                      openActionDialog: false,
                      openEditDialog: true,
                    });
                  }}
                >
                  <EditIcon style={iconStyle} />
                  Rename
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    setAnchorEl(null);
                    console.log("Upload Project", project);
                    // upload new file
                    setFilePath(
                      `${project.name}/${findPath(project, "id", item.id)}`
                    );
                    setAppState({
                      ...appState,
                      projectId: project.id,
                      folderId: item.id,
                    });
                    setSection("upload");
                    setCurrentSection("upload");
                    // setDisplayItems({
                    //   ...displayItems,
                    //   openActionDialog: false,
                    //   openFileUploader: true,
                    // });
                  }}
                >
                  <CloudUploadIcon style={iconStyle} />
                  Upload
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    setAnchorEl(null);
                    setDisplayItems({
                      ...displayItems,
                      openActionDialog: false,
                      moveMenuDisplay: true,
                      menuType: "project",
                    });
                  }}
                >
                  <ExitToAppIcon style={iconStyle} />
                  Move
                </MenuItem>
                {/* <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    setAnchorEl(null);
                    setDisplayItems({
                      ...displayItems,
                      openActionDialog: false,
                    });
                    setAppState({
                      ...appState,
                      projectId: project.id,
                      folderId: item.id,
                      fileId: null,
                    });
                  }}
                >
                  <EditIcon style={iconStyle} />
                  Create
                </MenuItem> */}
                <MenuItem
                  onClick={(e) => {
                    setAddWMSDialog(true);
                    setAnchorEl(null);
                    setAppState({
                      ...appState,
                      projectId: project.id,
                      folderId: item.id,
                    });
                  }}
                >
                  <MapIcon style={iconStyle} />
                  Add WMS/WMTS
                </MenuItem>
              </>
            )}
            {project.permission?.download && (
              <MenuItem
                onClick={(e) => {
                  // setAddWMSDialog(true);
                  // setAnchorEl(null);
                  // setAppState({
                  //   ...appState,
                  //   projectId: project.id,
                  //   folderId: item.id,
                  // });
                  dispatch({
                    type: SET_MESSAGE,
                    payload: {
                      message: "Your download is getting ready",
                      severity: "success",
                      isOpen: true,
                    },
                  });
                  setAnchorEl(null);
                  downloadFolder(item.id).then((res) => {
                    if (res.data.signedUrl) {
                      let a = document.createElement("a");
                      a.href = res.data.signedUrl;
                      a.click();
                    } else {
                      if (secureLocalStorage.getItem("user") == "share") {
                        dispatch({
                          type: SET_MESSAGE,
                          payload: {
                            message: "Download is being prepared",
                            severity: "success",
                            isOpen: true,
                          },
                        });
                        pushPendingDownload(item.id);
                        setAnchorEl(null);
                      } else {
                        dispatch({
                          type: SET_MESSAGE,
                          payload: {
                            message:
                              "This is a big folder, you will receive the download link through email shortly.",
                            severity: "success",
                            isOpen: true,
                          },
                        });
                        setAnchorEl(null);
                      }
                    }
                  });
                }}
              >
                <CloudDownloadIcon style={iconStyle} />
                Download
              </MenuItem>
            )}
            {project.permission?.delete && (
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  setAnchorEl(null);
                  setDisplayItems({
                    ...displayItems,
                    openActionDialog: false,
                  });
                  Modal.confirm({
                    title: "Are you sure you want to delete this Folder?",
                    icon: <ExclamationCircleOutlined />,
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",
                    onOk: () => {
                      // dispatch({ type: LOADING_MESSAGE, isLoading: true });
                      errorDisplay("success", "Item is being deleted");
                      deleteFolder(project.id, item.id).then((res) => {
                        if (res?.status === 200) {
                          // dispatch({ type: LOADING_MESSAGE, isLoading: false });
                          // *** verify it i removed code
                          updateLocalStorage(
                            res?.data?.deletedFolder?.storageDetail
                              ?.storageCapacity,
                            res?.data?.deletedFolder?.storageDetail?.usedStorage
                          );
                          const newFiles = [];
                          uploadFilesImagery.forEach((k) => {
                            if (
                              k.projectId != project.id &&
                              k.folderId != item.id
                            ) {
                              newFiles.push(k);
                            }
                          });
                          viewer.imageryLayers._layers.forEach((imgLayer) => {
                            if (
                              project.id ==
                                imgLayer?._imageryProvider?.item?.projectId &&
                              item.id ==
                                imgLayer?._imageryProvider?.item?.folderId
                            ) {
                              viewer.imageryLayers.remove(imgLayer);
                            }
                          });
                          console.log(
                            "uploadFilesImagery",
                            uploadFilesImagery,
                            newFiles,
                            project.id,
                            item.id
                          );
                          setUploadFilesImagery(newFiles);
                          // let newfiles = uploadFilesImagery.filter(
                          //   (delItem) => delItem.id != item.id
                          // );
                          // setUploadFilesImagery(newfiles);
                          setAppState({
                            ...appState,
                            layerArray: appState.layerArray.filter(
                              (itemLayer) => itemLayer.folderId !== item.id
                            ),
                            reloadProjects: true,
                            folderId: null,
                          });
                          errorDisplay(
                            "success",
                            "Folder deleted successfully"
                          );
                        } else {
                          errorDisplay("error", "Request Unsuccessful");
                        }
                      });
                    },
                  });
                }}
              >
                <DeleteIcon style={iconStyle} />
                Delete
              </MenuItem>
            )}
            {!project.permission?.edit &&
              !project.permission?.delete &&
              !project.permission?.download && (
                <>
                  <MenuItem
                    disabled
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    No action allowed
                  </MenuItem>
                </>
              )}
          </>
        ) : (
          <>
            {project.permission?.edit && (
              <MenuItem
                disabled={
                  item.status?.status?.toLowerCase().includes("processing") ||
                  item.status?.status?.toLowerCase().includes("pending")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setAnchorEl(null);
                  setAppState({
                    ...appState,
                    projectId: project.id,
                    folderId: item.folderId,
                    fileId: item.id,
                  });
                  setDisplayItems({
                    ...displayItems,
                    name: item.name,
                    editType: "layer",
                    openActionDialog: false,
                    openEditDialog: true,
                  });
                }}
              >
                <EditIcon style={iconStyle} />
                Rename
              </MenuItem>
            )}
            {!secureLocalStorage.getItem("shareZoom") && item.layerType !== 8 && (
              <MenuItem
                disabled={
                  !JSON.parse(secureLocalStorage.getItem("restrictions"))
                    ?.shareLink
                }
                onClick={(event) => {
                  event.stopPropagation();
                  // if (
                  //   secureLocalStorage.getItem("organizationName") &&
                  //   secureLocalStorage.getItem("organizationName") != "null" &&
                  //   secureLocalStorage.getItem("organizationName") !=
                  //     "undefined"
                  // ) {
                  // } else {
                  //   dispatch({
                  //     type: SET_MESSAGE,
                  //     payload: {
                  //       message: "Please set up your team first!",
                  //       severity: "error",
                  //       isOpen: true,
                  //     },
                  //   });
                  //   setAnchorEl(null);
                  //   let element = document.getElementById("createHighlight");
                  //   element.classList.add("blink-Button");
                  //   setTimeout(() => {
                  //     element.classList.remove("blink-Button");
                  //   }, 5000);
                  //   return;
                  // }
                  setAppState({
                    ...appState,
                    projectId: project.id,
                    folderId: item.id,
                  });
                  setDisplayItems({
                    ...displayItems,
                    openSharingDialog: true,
                    name: project.name,
                    id: project.id,
                  });
                  setAnchorEl(null);
                }}
              >
                <ShareIcon style={iconStyle} />
                Share
              </MenuItem>
            )}

            {item.data && (
              <>
                {project.permission?.download && (
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      setAnchorEl(null);
                      setDisplayItems({
                        ...displayItems,
                        openActionDialog: false,
                      });
                      getSingleLayer(item.id).then((newItem) => {
                        let newLayer = newItem.data.layer;
                        if (
                          (newLayer.layerType == 6 &&
                            !newLayer.data.includes(".zip") &&
                            !newLayer.data.includes(".laz")) ||
                          // newLayer.layerType == 5 ||
                          newLayer.layerType == 2
                        ) {
                          if (newLayer.layerType == 6) {
                            // setDisplayItems({
                            //   ...displayItems,
                            //   openActionDialog: false,
                            // });
                            let urltodownload = newLayer.data.includes(".zip")
                              ? newLayer.data
                                  .replace(/['"]+/g, "")
                                  .replace(
                                    API_URL.includes("devapi")
                                      ? "https://dev-mg-zip.s3.us-west-1.amazonaws.com/"
                                      : "https://mg-zip.s3.us-west-1.amazonaws.com/",
                                    API_URL + "s3/zip/"
                                  ) +
                                "?access_token=" +
                                authHeader()
                              : newLayer.data
                                  .replace(/['"]+/g, "")
                                  .replace(
                                    API_URL.includes("devapi")
                                      ? "https://dev-pointscloud-input.s3.us-west-1.amazonaws.com/"
                                      : "https://pointscloud-input.s3.us-west-1.amazonaws.com/",
                                    API_URL + "s3/pointcloud-in/"
                                  ) +
                                "?access_token=" +
                                authHeader();
                            let a = document.createElement("a");
                            a.href = urltodownload;
                            a.click();
                            return;
                          }
                          if (newLayer.layerType == 2) {
                            // setDisplayItems({
                            //   ...displayItems,
                            //   openActionDialog: false,
                            // });
                            let urltodownload =
                              newLayer.data
                                .replace(/['"]+/g, "")
                                .replace(
                                  API_URL.includes("devapi")
                                    ? "https://dev-mg-tiff.s3.us-west-1.amazonaws.com/"
                                    : "https://mg-tiff.s3.us-west-1.amazonaws.com/",
                                  API_URL + "s3/map-in/"
                                ) +
                              "?access_token=" +
                              authHeader();
                            let a = document.createElement("a");
                            a.href = urltodownload;
                            a.click();
                            return;
                          }
                          if (newLayer.data) {
                            // setDisplayItems({
                            //   ...displayItems,
                            //   openActionDialog: false,
                            // });
                            // downloadFile(item.data.replace(/['"]+/g, ""), item.id);
                            let a = document.createElement("a");
                            a.href = newLayer.data.replace(/['"]+/g, "");
                            a.click();
                          }
                        } else {
                          if (newLayer.signedUrl) {
                            let a = document.createElement("a");
                            a.href = newLayer.signedUrl;
                            a.click();
                            // setDisplayItems({
                            //   ...displayItems,
                            //   openActionDialog: false,
                            // });
                            // downloadFile(item.data.replace(/['"]+/g, ""), item.id);
                          }
                        }
                      });
                    }}
                  >
                    <CloudDownloadIcon style={iconStyle} />
                    Download
                  </MenuItem>
                )}
                {item.layerType !== 8 ? (
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      setAnchorEl(null);
                      // setAppState({ ...appState, projectId: project.id, folderId: item.id });
                      // setDisplayItems({ ...displayItems, name: "", editType: "folder", openActionDialog: false, openFolderDialog: true });
                      console.log(
                        "Zoom To",
                        project.id,
                        item.id,
                        "ITEM RenderActionMenu",
                        appState
                      );
                      appState.layerArray.forEach((n, m) => {
                        if (item.id === n.id) {
                          fileAccordianOnDoubleClick(e, item);
                          // console.log("ID Matched", n);
                          // if (n.entity) {
                          //   flyToCordVectors(n.entity);
                          // } else {
                          //   const cords = n.latLong
                          //     ? n.latLong?.coordinates
                          //     : null;
                          //   if (cords) {
                          //     cords && cords.minx && flyToRectangle(cords);
                          //     cords && flyToCords(cords[1], cords[0]);
                          //   } else {
                          //     dispatch({
                          //       type: SET_MESSAGE,
                          //       payload: {
                          //         message:
                          //           "There is a problem with the geolocation of this file, please check the file or contact support.",
                          //         severity: "error",
                          //         isOpen: true,
                          //       },
                          //     });
                          //   }
                          // }
                        }
                      });
                    }}
                  >
                    <ZoomInIcon style={iconStyle} />
                    Zoom
                  </MenuItem>
                ) : null}
              </>
            )}
            {project.permission?.edit && (
              <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  setAnchorEl(null);
                  setDisplayItems({
                    ...displayItems,
                    openActionDialog: false,
                    moveMenuDisplay: true,
                    menuType: "file",
                  });
                }}
              >
                <ExitToAppIcon style={iconStyle} />
                Move
              </MenuItem>
            )}
            {project.permission?.delete && (
              <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  setAnchorEl(null);
                  setDisplayItems({
                    ...displayItems,
                    openActionDialog: false,
                  });
                  Modal.confirm({
                    title: "Are you sure you want to delete this Layer?",
                    icon: <ExclamationCircleOutlined />,
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",
                    onOk: () => {
                      // dispatch({ type: LOADING_MESSAGE, isLoading: true });
                      errorDisplay("success", "Item is being deleted");
                      deleteLayer(project.id, item.folderId, item.id).then(
                        (res) => {
                          if (res?.data?.status === 200) {
                            // dispatch({
                            //   type: LOADING_MESSAGE,
                            //   isLoading: false,
                            // });
                            console.log(
                              "itemEntity",
                              item,
                              viewer.entities._entities
                            );
                            if (
                              item.layerType === 5 &&
                              item.entity
                              // &&
                              // item?.status?.type === "multires" &&
                              // item.thumbnail
                            ) {
                              viewer.entities.remove(item.entity);
                              viewer.scene.primitives.remove(item.entity);
                              updateMapFeatures(item.id);
                            }
                            //  else if (item.layerType === 5 && item.entity) {
                            //   console.log("itemEntity5", item);
                            //   updateMapFeatures(item.id);
                            // }
                            let checkLayerWms = viewer.imageryLayers._layers.filter(
                              (imgLayer) =>
                                imgLayer.imageryProvider?.layers?.includes(
                                  item.tableName
                                )
                            );
                            if (checkLayerWms.length)
                              viewer.imageryLayers.remove(checkLayerWms[0]);
                            pendingUpdate(item.id);
                            updateLocalStorage(
                              res?.data?.storageDetail?.storageCapacity,
                              res?.data?.storageDetail?.usedStorage
                            );
                            setRightDrawer(0);
                            document.getElementsByClassName(
                              "cesium-viewer-toolbar"
                            )[0].style.right = "5px";
                            handleInfoDrawerClose();
                            // *** warning: this will effect drag and drop order
                            let appStateTemp = appState;
                            appStateTemp.projects.forEach((projectItem) => {
                              if (projectItem.id === project.id) {
                                removeItemFromProject(
                                  projectItem.children,
                                  item.folderId,
                                  item.id
                                );
                              }
                            });
                            if (appStateTemp.projectsFilter?.length) {
                              appStateTemp.projectsFilter.forEach(
                                (projectItem) => {
                                  if (projectItem.id === project.id) {
                                    removeItemFromProject(
                                      projectItem.children,
                                      item.folderId,
                                      item.id
                                    );
                                  }
                                }
                              );
                            }
                            appStateTemp.layerArray = appStateTemp.layerArray.filter(
                              (itemLayer) => itemLayer.id !== item.id
                            );
                            setAppState({ ...appStateTemp });
                            if (item.layerType == 6) {
                              appStateTemp = appState;
                              appStateTemp.projects.forEach((projectItem) => {
                                if (projectItem.id === project.id) {
                                  removeItemFromProject(
                                    projectItem.children,
                                    item.folderId,
                                    item.id
                                  );
                                }
                              });
                              if (appStateTemp.projectsFilter?.length) {
                                appStateTemp.projectsFilter.forEach(
                                  (projectItem) => {
                                    if (projectItem.id === project.id) {
                                      removeItemFromProject(
                                        projectItem.children,
                                        item.folderId,
                                        item.id
                                      );
                                    }
                                  }
                                );
                              }
                              setAppState({ ...appStateTemp });
                            }
                            updateLocalStorage(
                              res?.data?.storageDetail?.storageCapacity,
                              res?.data?.storageDetail?.usedStorage
                            );

                            let newfiles = uploadFilesImagery.filter(
                              (delItem) => delItem.id != item.id
                            );

                            setUploadFilesImagery(newfiles);
                            //const viewer = mapRef.current.cesiumElement;
                            if (item.layerType == 6 && item.entity) {
                              viewer.scene.primitives.remove(item.entity);
                              updateMapFeatures(item.id);
                            } else if (item.layerType === 7) {
                              if (viewer.imageryLayers._layers.length > 0) {
                                let removeItem = null;
                                viewer.imageryLayers._layers.forEach((k, i) => {
                                  if (k.itemId === item.id) {
                                    removeItem = k;
                                  }
                                });
                                if (removeItem) {
                                  viewer.imageryLayers.remove(removeItem);
                                  updateMapFeatures(item.id);
                                }
                              }
                            }
                            // else if (
                            //   item.layerType === 5 &&
                            //   viewer.entities.length > 0 &&
                            //   item.entity
                            // ) {
                            //   console.log("itemEntity5", item.entity);
                            //   viewer.entities.remove(item.entity);
                            // }
                            // setAppState({ ...appState, reloadProjects: true });
                            // errorDisplay("success", "Deleting Item");
                            errorDisplay(
                              "success",
                              "Layer deleted successfully"
                            );
                          } else {
                            errorDisplay("error", "Request Unsuccessful");
                          }
                        }
                      );
                    },
                  });
                }}
              >
                <DeleteIcon style={iconStyle} />
                Delete
              </MenuItem>
            )}
          </>
        ))}
    </Menu>
  );
};

export default FileActionMenu;
