import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import EditIcon from "@material-ui/icons/Edit";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const ProjectActionMenu = (props) => {
  const {
    setDisplayItems,
    setFilePath,
    handleOpenUploadWizard,
    findPath,
    setSection,
    setCurrentSection,
    iconStyle,
    displayItems,
    dispatch,
    appState,
    SET_MESSAGE,
    anchorElProjBtn,
    setAnchorElProjBtn,
  } = props;

  return (
    <Menu
      id="card-actions-menu"
      anchorEl={anchorElProjBtn}
      keepMounted
      open={Boolean(anchorElProjBtn)}
      onClose={() => {
        setAnchorElProjBtn(null);
      }}
    >
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          handleOpenUploadWizard();
          setAnchorElProjBtn(null);
        }}
      >
        <CloudUploadIcon style={iconStyle} />
        Upload Project
      </MenuItem>
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          setDisplayItems({
            ...displayItems,
            openProjectDialog: true,
          });
          setAnchorElProjBtn(null);
        }}
      >
        <EditIcon style={iconStyle} />
        Create Project
      </MenuItem>
      <MenuItem
        onClick={(event) => {
          console.log("appState", appState);
          if (appState.folderId) {
            setDisplayItems({
              ...displayItems,
              name: "",
              editType: "folder",
              openActionDialog: false,
              openFolderDialog: true,
            });
          } else {
            if (appState.projectId) {
              setDisplayItems({
                ...displayItems,
                editType: "project",
                openFolderDialog: true,
              });
            } else {
              dispatch({
                type: SET_MESSAGE,
                payload: {
                  message: "No project/folder has been selected",
                  severity: "error",
                  isOpen: true,
                },
              });
            }
          }

          event.stopPropagation();

          setAnchorElProjBtn(null);
        }}
      >
        <CreateNewFolderIcon style={iconStyle} />
        Create Folder
      </MenuItem>
      <MenuItem
        onClick={(event) => {
          if (appState.folderId) {
            appState.projects.forEach((k, i) => {
              if (appState.projectId === k.id) {
                setFilePath(
                  `${k.name}/${findPath(k, "id", appState.folderId)}`
                );
                setSection("upload");
                setCurrentSection("upload");
                // setDisplayItems({
                //   ...displayItems,
                //   openActionDialog: false,
                //   openFileUploader: true,
                // });
              }
            });

            // setFilePath(
            //   `${project.name}/${findPath(project, "id", item.id)}`
            // );
            // setDisplayItems({
            //   ...displayItems,
            //   openActionDialog: false,
            //   openFileUploader: true,
            // });
          } else {
            dispatch({
              type: SET_MESSAGE,
              payload: {
                message: "No folder has been selected",
                severity: "error",
                isOpen: true,
              },
            });
          }

          event.stopPropagation();

          setAnchorElProjBtn(null);
        }}
      >
        <CloudUploadIcon style={iconStyle} />
        Upload to Current Folder
      </MenuItem>
    </Menu>
  );
};

export default ProjectActionMenu;
