import React, { useState, useRef, useEffect } from "react";
import "./SplitScreen.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker, Calendar } from "react-date-range";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { SplitDirection } from "cesium";
import "./custom.css";
import SwapHoriz from "@material-ui/icons/SwapHoriz";

const SplitScreen = (props) => {
  const childRef = useRef(null);
  const [stateLeft, setStateLeft] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 59, 999)),
      key: "selection",
    },
  ]);

  const handleDateChange = (item) => {
    const selection = item.selection;
    const updatedRange = {
      startDate: new Date(selection.startDate.setHours(0, 0, 0, 0)),
      endDate: new Date(selection.endDate.setHours(23, 59, 59, 999)),
      key: "selection",
    };

    return updatedRange;
  };
  const [stateRight, setStateRight] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 59, 999)),
      key: "selection",
    },
  ]);

  const [dateRangePicker, setDateRangePicker] = useState(false);
  const [workFlow, setWorkFlow] = useState(null);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: 999999999999999999,
      pointerEvents: "none",
    },
    input: {
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:after": {
        borderBottom: "none",
      },
    },
  }));
  const classes = useStyles();

  const filterLayerArrayFn = (array, startDateP, endDateP) => {
    const startDate = new Date(startDateP);
    const endDate = new Date(endDateP);

    const filteredDates = array.filter((obj) => {
      if (obj.dateCollected != null) {
        const dateToCheck = new Date(obj.dateCollected);

        return dateToCheck >= startDate && dateToCheck <= endDate;
      }
      return false;
    });
    return filteredDates;
  };

  const removeCommonIDs = (list, commonIDs) => {
    return list.filter((id) => !commonIDs.includes(id));
  };

  const SplitFunctions = () => {
    //Code to filter on basis of "dateCollected" from appState.layerArray
    // console.log(
    //   "dateValuesChkLeft",
    //   stateLeft[0].startDate,
    //   stateLeft[0].endDate,
    //   props.layerArray
    // );
    const filterLayerArrayLeft = filterLayerArrayFn(
      props.layerArray,
      stateLeft[0].startDate,
      stateLeft[0].endDate
    );
    const filterLayerArrayRight = filterLayerArrayFn(
      props.layerArray,
      stateRight[0].startDate,
      stateRight[0].endDate
    );
    //Code to get only ids
    const filterLayerArrayLeftIds = filterLayerArrayLeft.map((obj) => obj.id);
    const filterLayerArrayRightIds = filterLayerArrayRight.map((obj) => obj.id);
    // Finding common IDs for the third list
    const commonLayerArrayIds = filterLayerArrayLeftIds.filter((id) =>
      filterLayerArrayRightIds.includes(id)
    );

    // Removing common IDs from the first and second lists
    const updatedFilterLayerArrayLeftIds = removeCommonIDs(
      filterLayerArrayLeftIds,
      commonLayerArrayIds
    );
    const updatedFilterLayerArrayRightIds = removeCommonIDs(
      filterLayerArrayRightIds,
      commonLayerArrayIds
    );

    //Getting the ids not in left,right and common list

    const combinedArrayIdsNotSelected = commonLayerArrayIds.concat(
      updatedFilterLayerArrayLeftIds,
      updatedFilterLayerArrayRightIds
    );

    const filterMapFeatureObjsLeft = props.filterObjectsInIdList(
      props.mapFeatures,
      updatedFilterLayerArrayLeftIds
    );
    const filterMapFeatureObjsRight = props.filterObjectsInIdList(
      props.mapFeatures,
      updatedFilterLayerArrayRightIds
    );
    const filterMapFeatureObjsCommon = props.filterObjectsInIdList(
      props.mapFeatures,
      commonLayerArrayIds
    );
    const filterMapFeatureObjsNotSelected = props.filterObjectsNotInIdList1(
      props.mapFeatures,
      combinedArrayIdsNotSelected
    );

    const objReturn = {
      filterMapFeatureObjsLeft: filterMapFeatureObjsLeft,
      filterMapFeatureObjsRight: filterMapFeatureObjsRight,
      filterMapFeatureObjsCommon: filterMapFeatureObjsCommon,
      filterMapFeatureObjsNotSelected: filterMapFeatureObjsNotSelected,
    };
    return objReturn;
  };

  useEffect(() => {
    const returnValue = SplitFunctions();
    //Apply Split Screen Functions on the Features
    returnValue.filterMapFeatureObjsLeft.forEach((k) => {
      if (k.type === 6 || k.type === 7 || k.type === 2 || k.type === 5) {
        k.entity.splitDirection = SplitDirection.LEFT;
        k.entity.show = k.show;
      }
    });
    returnValue.filterMapFeatureObjsRight.forEach((k) => {
      if (k.type === 6 || k.type === 7 || k.type === 2 || k.type === 5) {
        k.entity.splitDirection = SplitDirection.RIGHT;
        k.entity.show = k.show;
      }
    });
    returnValue.filterMapFeatureObjsCommon.forEach((k) => {
      if (k.type === 6 || k.type === 7 || k.type === 2 || k.type === 5) {
        k.entity.splitDirection = SplitDirection.BOTH;
        k.entity.show = k.show;
      }
    });

    //Hiding Not selected Map Features
    returnValue.filterMapFeatureObjsNotSelected.forEach((k) => {
      if (k.type === 6 || k.type === 7 || k.type === 2 || k.type === 5) {
        k.entity.show = false;
      }
    });

    return () => {
      console.log("ComponentUnmount");
      // Perform cleanup tasks here
      // This code will run when the component is unmounted
      //Apply Split Screen Functions on the Features

      //Showing Not selected Map Features
      returnValue.filterMapFeatureObjsNotSelected.forEach((k) => {
        if (k.type === 6 || k.type === 7 || k.type === 2 || k.type === 5) {
          k.entity.show = k.show;
        }
      });
      props.SplitScreenUnMountFn();
    };
  }, [stateLeft, stateRight, props.dateInfoPanel]);
  return (
    <>
      <div className="slider" id="slider">
        <Tooltip title={"Compare"} placement="left">
          <IconButton
            style={{
              backgroundColor: "white",
              position: "absolute",
              top: "50%",
              right: "-21px",
              // left: "50%",
              zIndex: 9998,
              cursor: "ew-resize",
            }}
            onClick={(e) => {}}
            aria-label="delete"
          >
            <SwapHoriz />
          </IconButton>
        </Tooltip>
      </div>

      <div className="leftDatePicker">
        <TextField
          // error={textValLayerName.error}
          style={{ fontSize: 15, margin: "5px" }}
          id="dateLeft"
          className={classes.input}
          autoFocus
          label="Select Time Range"
          fullWidth
          variant="standard"
          onClick={() => {
            setDateRangePicker(true);
            setWorkFlow("Left");
            console.log("mapFeatures", props.mapFeatures);
          }}
          value={`${stateLeft[0].startDate.toDateString()} - ${stateLeft[0].endDate.toDateString()}`}
          onChange={(event) => {}}
        />
      </div>
      <div className="rightDatePicker">
        <TextField
          style={{ fontSize: 15, margin: "5px" }}
          id="dateRight"
          className={classes.input}
          autoFocus
          label="Select Time Range"
          fullWidth
          variant="standard"
          onClick={() => {
            setDateRangePicker(true);
            setWorkFlow("Right");
          }}
          value={`${stateRight[0].startDate.toDateString()} - ${stateRight[0].endDate.toDateString()}`}
          onChange={(event) => {}}
        />
      </div>
      {dateRangePicker && (
        <Backdrop className={classes.backdrop} open={true} invisible={true}>
          <div
            style={{
              display: "flex",

              marginTop: "15vh",
              marginRight: workFlow === "Left" && "40%",
              marginLeft: workFlow === "Right" && "40%",
              pointerEvents: "auto",
            }}
            ref={childRef}
          >
            <div>
              {workFlow === "Left" && (
                <DateRangePicker
                  onChange={(item) => {
                    const timeDate = handleDateChange(item);
                    setStateLeft([timeDate]);
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={stateLeft}
                  direction="horizontal"
                />
              )}
              {workFlow === "Right" && (
                <DateRangePicker
                  onChange={(item) => {
                    const timeDate = handleDateChange(item);
                    setStateRight([timeDate]);
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={stateRight}
                  direction="horizontal"
                />
              )}
            </div>
            <div
              style={{
                color: "white",

                alignSelf: "flex-start",
                marginTop: "2%",
              }}
            >
              <Tooltip title={"Close Calender"}>
                <IconButton
                  onClick={(e) => {
                    setDateRangePicker(false);
                  }}
                  aria-label="delete"
                  style={{
                    margin: "20px",
                    backgroundColor: "#901da1e6",
                    color: "white",
                    marginTop: "5px",
                    height: "37px",
                    width: "37px",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </Backdrop>
      )}
    </>
  );
};

export default SplitScreen;
