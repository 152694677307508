import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { cloneDeep, groupBy } from "lodash";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { config } from "./../../../../common/common";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";
import {
  addNewFolder,
  addNewLayer,
} from "../../../../../services/user.service";
import { useDispatch } from "react-redux";
import { SET_MESSAGE } from "../../../../../actions/types";
import DescriptionIcon from "@material-ui/icons/Description";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import BackspaceIcon from "@material-ui/icons/Backspace";
import CancelIcon from "@material-ui/icons/Cancel";
import secureLocalStorage from "react-secure-storage";
import { fromBlob } from "geotiff";
import { Modal } from "antd";
function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}
var AWS = require("aws-sdk");
const MetaWizardUploader = (props) => {
  const {
    appState,
    allFolders,
    updateFunction,
    filePath,
    newUploadFiles,
    setNewUploadfiles,
    doneUploads,
    setDoneUploads,
    failedUploads,
    stateChecker,
    wizardUploaderFiles,
    setWizardUploaderFiles,
    folderName,
    setFolderName,
    setAppState,
    setFolder,
    setFilePath,
    accepts,
  } = props;
  const dispatch = useDispatch();
  const errorDisplay = (type, message) => {
    dispatch({
      type: SET_MESSAGE,
      payload: { message: message, severity: type, isOpen: true },
    });
  };
  const [check1, setCheck] = useState(true);
  const updateLocalStorage = (storageCapacity, usedStorage) => {
    if (storageCapacity && usedStorage) {
      secureLocalStorage.setItem(
        "availableStorage",
        storageCapacity - usedStorage
      );
    }
  };
  function addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () =>
        resolve({
          height: img.height,
          width: img.width,
        });
      img.onerror = reject;
      img.src = src;
    });
  }
  function CircularProgressWithLabel(props) {
    return (
      <Box style={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          className={props.className ? "circle" + props.className : ""}
          variant="determinate"
          size={35}
        />
        <Box
          style={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            className={props.className ? props.className : ""}
            variant="caption"
            component="div"
            color="text.secondary"
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  const uploadFile = async (
    bucket,
    uniqueID,
    newFileName,
    file,
    dataObject
  ) => {
    var s3 = new AWS.S3({
      accessKeyId: secureLocalStorage.getItem("ACCESS_ID"),
      secretAccessKey: secureLocalStorage.getItem("SECRET_KEY"),
      sessionToken: secureLocalStorage.getItem("SessionToken"),
      region: config.REGION,
    });
    let params = {
      Bucket: bucket,
      Key: newFileName,
      Body: file,
    };
    let options = { partSize: 5 * 1024 * 1024, queueSize: 20 };
    try {
      const stored = await s3
        .upload(params, options)
        .on("httpUploadProgress", function(progress) {
          if (!newFileName.includes("userGenerated")) {
            let progressPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            // console.log(progressPercentage);
            // setProgress(progressPercentage);
            document.getElementsByClassName(
              file.unique
            )[0].innerHTML = `${progressPercentage}%`;
            document.querySelector(
              ".circle" + file.unique + " svg circle"
            ).style["stroke-dasharray"] =
              // Number(
              //   document.querySelector(
              //     ".circle" +
              //       file.name.replace(" ", "_").replace(".", "_") +
              //       file.size +
              //       " svg circle"
              //   ).style["stroke-dasharray"]
              // )
              126.92 + Number(progressPercentage) * 1.27;
            // progressBar.style.width = progressPercentage + "%";
            // if (progressPercentage < 100) {
            // fileUpload.progressStatus = progressPercentage;
            // } else
            secureLocalStorage.setItem("uploading", 1);
            if (progressPercentage == 100) {
              //   doneUploads.push(file.size);
              //   setNewUploadfiles(newUploadFiles.slice(0));
            }
          }
          // fileUpload.progressStatus = progressPercentage;

          // fileUpload.status = "Uploaded";
          // }
        })
        .promise();
      const layerToBeAdded = [
        {
          attributes: {
            description: "",
            color: "",
            fillColor: "",
            strokeColor: "",
            transparency: "",
            strokeTransparency: "",
            fillTransparency: "",
            tags: [],
            links: [],
          },
          name: file.name,
          data: {
            url: stored.Location,
            // url: !newFileName.includes(".zip")
            // ? stored.Location
            // : stored.Location.split(".zip")[0] + ".kml",
            size: file.size / 1024 / 1024,
          },
          referenceID: uniqueID,
          status: {
            status:
              dataObject.layerType == 4 &&
              !file.name.includes(".zip") &&
              !file.name.includes(".dxf") &&
              !file.name.includes(".json") &&
              !file.name.includes(".gpx") &&
              !file.name.includes(".geojson")
                ? "Done"
                : "Processing",
          },
        },
      ];
      const layerResponse = await addNewLayer(
        layerToBeAdded,
        dataObject.projectId,
        dataObject.parentId,
        dataObject.layerType
      );
      if (layerResponse && layerResponse.status === 200) {
        // setProgress(dataObject.progress);
        updateLocalStorage(
          layerResponse?.data?.storageDetail?.storageCapacity,
          layerResponse?.data?.storageDetail?.usedStorage
        );

        if (layerResponse?.data?.layers.length > 0) {
          // *** warning: this will effect drag and drop order
          updateFunction(layerResponse, dataObject, newFileName);
        }
      } else {
        console.log("Testing", "create layer error", layerResponse);
      }
    } catch (error) {
      failedUploads.push(file.unique);
      console.log("Testing", "Catch block s3", error);
      errorDisplay(
        "error",
        error.code == "InvalidToken"
          ? "Upload token is expired, please relogin."
          : `We are facing problem in uploading this ${file.name} file`
      );
    }
  };
  return (
    <span className="layerBox">
      {/* <Box className="TitleSection">
        {appState.projectId ? (
          <h3>
            Uploading in{" "}
            {appState.projects.filter(
              (itemProj) => itemProj.id == appState.projectId
            )[0]?.name +
              " / " +
              allFolders.filter((itemFol) => itemFol.id == appState.folderId)[0]
                ?.name}
          </h3>
        ) : (
          "Uploads"
        )}
      </Box> */}
      {/* {appState.projectId ? (
        <h4>
          Uploading in{" "}
          {appState.projects.filter(
            (itemProj) => itemProj.id == appState.projectId
          )[0]?.name +
            " / " +
            allFolders.filter((itemFol) => itemFol.id == appState.folderId)[0]
              ?.name}
        </h4>
      ) : (
        ""
      )} */}
      <input
        disabled={!appState.folderId && !folderName}
        hidden={!appState.projectId}
        type="file"
        id="files1"
        title=""
        className={
          !appState.folderId && !folderName
            ? "custom-file-input-folder"
            : !appState.folderId && folderName
            ? "custom-file-input-folder-create"
            : "custom-file-input"
        }
        name="files"
        accept={iOS() ? "" : accepts}
        multiple
        onChange={async (event) => {
          let filesToUpload = event.target.files;
          if (folderName) {
            addNewFolder(appState.projectId, {
              name: folderName,
            })
              .then((res) => {
                if (res?.data?.status === 200) {
                  setAppState({
                    ...appState,
                    reloadProjects: true,
                    folderId: res?.data?.folder.id,
                    fileId: null,
                  });
                  setFolderName("");
                  setFolder(res?.data?.folder);
                  appState.projects.forEach((k, i) => {
                    if (appState.projectId === k.id) {
                      allFolders?.push({
                        ...res?.data?.folder,
                        projectName: k.name,
                        children: [],
                      });
                      setFilePath(`${k.name}/${res?.data?.folder.name}`);
                    }
                  });
                  setTimeout(() => {
                    setWizardUploaderFiles(filesToUpload);
                  }, 300);
                } else {
                  errorDisplay(
                    "error",
                    res?.data?.message || "Request Unsuccessful"
                  );
                }
              })
              .catch((error) => {
                errorDisplay(
                  "error",
                  error.response ? error.response.data : "Request Unsuccessful"
                );
              });
          } else {
            setWizardUploaderFiles(filesToUpload);
          }
        }}
      ></input>

      <Button
        disabled={
          newUploadFiles.filter(
            (itemFile) =>
              doneUploads.includes(itemFile.unique) ||
              failedUploads.includes(itemFile.unique)
          ).length
            ? false
            : true
        }
        hidden={!newUploadFiles.length}
        variant="outlined"
        style={{
          textTransform: "none",
          display: "flex",
          margin: "auto !important",
          paddingInline: "10px",
        }}
        onClick={() => {
          setNewUploadfiles(
            newUploadFiles.filter(
              (itemFile) =>
                !doneUploads.includes(itemFile.unique) &&
                !failedUploads.includes(itemFile.unique)
            )
          );
        }}
      >
        Clear completed uploads
        <BackspaceIcon
          style={{
            marginLeft: "6px",
            height: 18,
            width: "auto",
            cursor: "pointer",
          }}
        />
      </Button>
      <List
        className={check1 ? "check" : ""}
        style={{ width: "100%", bgcolor: "background.paper" }}
      >
        {Object.keys(groupBy(newUploadFiles, "group")).map((itemAccordian) => {
          return (
            <Accordion
              defaultExpanded={props.openAccordiansUploader.includes(
                itemAccordian
              )}
              onChange={() => {
                props.accordianChange(itemAccordian);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <span style={{ fontSize: 14, fontWeight: 700, padding: 10 }}>
                  {itemAccordian +
                    ` ${
                      groupBy(newUploadFiles, "group")[
                        itemAccordian
                      ].filter((x) => doneUploads.includes(x.unique)).length
                    }/${
                      groupBy(newUploadFiles, "group")[itemAccordian].length
                    }`}
                </span>
              </AccordionSummary>
              <AccordionDetails>
                {groupBy(newUploadFiles, "group")[itemAccordian].map(
                  (item, index) => (
                    <>
                      <ListItem alignItems="center">
                        <ListItemAvatar>
                          <DescriptionIcon
                            style={{
                              color: "blue",
                              height: 27,
                              width: "auto",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          className="uploadName"
                          primary={`${item.name.slice(0, 20)}${
                            item.name.length > 20 ? "..." : ""
                          }`}
                        />
                        {doneUploads.includes(item.unique) ? (
                          <>
                            <CheckCircleIcon
                              style={{
                                height: 19,
                                width: "auto",
                                color: "#4BB543",
                              }}
                            />
                            {/* <BackspaceIcon
                                  onClick={() => {
                                    setNewUploadfiles(
                                      newUploadFiles.filter(
                                        (itemFile) =>
                                          itemFile.unique !== item.unique
                                      )
                                    );
                                  }}
                                  style={{
                                    height: 19,
                                    width: "auto",
                                    cursor: "pointer",
                                  }}
                                /> */}
                          </>
                        ) : failedUploads.includes(item.unique) ? (
                          <CancelIcon
                            style={{
                              height: 19,
                              width: "auto",
                              color: "red",
                            }}
                          />
                        ) : (
                          <CircularProgressWithLabel
                            className={item.unique}
                            value={0}
                          />
                        )}
                      </ListItem>
                      <Divider component="li" />
                    </>
                  )
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </List>
    </span>
  );
};

export default MetaWizardUploader;
