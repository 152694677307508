import React from "react";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import ShareIcon from "@material-ui/icons/Share";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Modal } from "antd";
import { findPath } from "../../../../common/common";
import { SET_MESSAGE } from "../../../../../actions/types";
import { useDispatch } from "react-redux";
import secureLocalStorage from "react-secure-storage";

const FolderActionMenu = (props) => {
  const dispatch = useDispatch();
  const {
    anchorElProj,
    actionStateProj,
    item,
    setAnchorElProj,
    setFilePath,
    setSection,
    setCurrentSection,
    project,
    deleteProjectFunction,
    setAppState,
    setDisplayItems,
    iconStyle,
    appState,
    displayItems,
  } = props;
  return (
    <Menu
      id="card-actions-menu"
      anchorEl={anchorElProj}
      keepMounted
      open={Boolean(anchorElProj) && actionStateProj.item === item}
      style={actionStateProj.anchorPosition}
      onClose={() => {
        setAnchorElProj(null);
      }}
    >
      {project.permission?.edit && (
        <>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              setAnchorElProj(null);
              let dataFolder;
              if (
                project.children.filter((item) => item.name == "data").length
              ) {
                dataFolder = project.children.filter(
                  (item) => item.name == "data"
                )[0];
              } else {
                dataFolder = project.children[0];
              }
              if (!dataFolder) {
                return;
              }
              setFilePath(
                `${project.name}/${findPath(project, "id", dataFolder.id)}`
              );
              setAppState({
                ...appState,
                projectId: project.id,
                folderId: dataFolder.id,
              });
              setSection("upload");
              setCurrentSection("upload");
            }}
          >
            {" "}
            <CloudUploadIcon style={iconStyle} />
            Upload
          </MenuItem>
          <MenuItem
            disabled={
              !JSON.parse(secureLocalStorage.getItem("restrictions"))?.shareLink
            }
            onClick={(event) => {
              event.stopPropagation();
              // if (
              //   secureLocalStorage.getItem("organizationName") &&
              //   secureLocalStorage.getItem("organizationName") != "null" &&
              //   secureLocalStorage.getItem("organizationName") != "undefined"
              // ) {
              // } else {
              //   dispatch({
              //     type: SET_MESSAGE,
              //     payload: {
              //       message: "Please set up your team first!",
              //       severity: "error",
              //       isOpen: true,
              //     },
              //   });
              //   setAnchorElProj(null);
              //   let element = document.getElementById("createHighlight");
              //   element.classList.add("blink-Button");
              //   setTimeout(() => {
              //     element.classList.remove("blink-Button");
              //   }, 5000);
              //   return;
              // }
              setAppState({
                ...appState,
                projectId: actionStateProj.project.id,
                folderId: null,
                fileId: null,
              });
              setDisplayItems({
                ...displayItems,
                openSharingDialog: true,
                name: actionStateProj.project.name,
                id: actionStateProj.project.id,
              });
              setAnchorElProj(null);
            }}
          >
            <ShareIcon style={iconStyle} />
            Share
          </MenuItem>
          {/* <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      // folder Upload call in project
                      setFilePath(`${project.name}`);
                      setDisplayItems({
                        ...displayItems,
                        openFolderUploader: true,
                      });
                      setAppState({
                        ...appState,
                        projectId: actionStateProj.project.id,
                        folderId: null,
                        fileId: null,
                      });
                      setAnchorElProj(null);
                    }}
                  >
                    <FolderSpecialIcon style={iconStyle} />
                    Upload Folder
                  </MenuItem> */}
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              setAppState({
                ...appState,
                projectId: actionStateProj.project.id,
                folderId: null,
                fileId: null,
              });
              setDisplayItems({
                ...displayItems,
                editType: "project",
                openFolderDialog: true,
              });
              setAnchorElProj(null);
            }}
          >
            <CreateNewFolderIcon style={iconStyle} />
            Add Folder
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              setAppState({
                ...appState,
                projectId: actionStateProj.project.id,
                folderId: null,
                fileId: null,
              });
              setDisplayItems({
                ...displayItems,
                name: actionStateProj.project.name,
                editType: "project",
                openEditDialog: true,
              });
              setAnchorElProj(null);
            }}
          >
            <EditIcon style={iconStyle} />
            Rename
          </MenuItem>
        </>
      )}
      {project.permission?.delete && (
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            Modal.confirm({
              title: "Are you sure you want to delete this Project?",
              icon: <ExclamationCircleOutlined />,
              okText: "Yes",
              okType: "danger",
              cancelText: "No",
              onOk: () => {
                deleteProjectFunction(actionStateProj.project.id);
              },
            });
            setAnchorElProj(null);
          }}
        >
          <DeleteIcon style={iconStyle} />
          Delete
        </MenuItem>
      )}
      {!project.permission?.edit && !project.permission?.delete && (
        <>
          <MenuItem
            disabled
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            No action allowed
          </MenuItem>
        </>
      )}
    </Menu>
  );
};

export default FolderActionMenu;
