import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { cloneDeep, groupBy } from "lodash";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { config } from "./../../../../common/common";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { addNewLayer } from "../../../../../services/user.service";
import { useDispatch } from "react-redux";
import { SET_MESSAGE } from "../../../../../actions/types";
import DescriptionIcon from "@material-ui/icons/Description";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import BackspaceIcon from "@material-ui/icons/Backspace";
import CancelIcon from "@material-ui/icons/Cancel";
import secureLocalStorage from "react-secure-storage";
import { fromBlob } from "geotiff";
import { Modal } from "antd";
function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}
var AWS = require("aws-sdk");
const MetaUploader = (props) => {
  const {
    appState,
    allFolders,
    updateFunction,
    filePath,
    newUploadFiles,
    setNewUploadfiles,
    doneUploads,
    setDoneUploads,
    failedUploads,
    stateChecker,
    wizardUploaderFiles,
    setWizardUploaderFiles,
    dateCollectedWizard,
  } = props;
  const dispatch = useDispatch();
  const errorDisplay = (type, message) => {
    dispatch({
      type: SET_MESSAGE,
      payload: { message: message, severity: type, isOpen: true },
    });
  };
  const [check1, setCheck] = useState(true);
  const updateLocalStorage = (storageCapacity, usedStorage) => {
    if (storageCapacity && usedStorage) {
      secureLocalStorage.setItem(
        "availableStorage",
        storageCapacity - usedStorage
      );
    }
  };
  function addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () =>
        resolve({
          height: img.height,
          width: img.width,
        });
      img.onerror = reject;
      img.src = src;
    });
  }
  function CircularProgressWithLabel(props) {
    return (
      <Box style={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          className={props.className ? "circle" + props.className : ""}
          variant="determinate"
          size={35}
        />
        <Box
          style={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            className={props.className ? props.className : ""}
            variant="caption"
            component="div"
            color="text.secondary"
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  const uploadFile = async (
    bucket,
    uniqueID,
    newFileName,
    file,
    dataObject,
    dateCollected
  ) => {
    var s3 = new AWS.S3({
      accessKeyId: secureLocalStorage.getItem("ACCESS_ID"),
      secretAccessKey: secureLocalStorage.getItem("SECRET_KEY"),
      sessionToken: secureLocalStorage.getItem("SessionToken"),
      region: config.REGION,
    });
    let params = {
      Bucket: bucket,
      Key: newFileName,
      Body: file,
    };
    let options = { partSize: 5 * 1024 * 1024, queueSize: 20 };
    try {
      const stored = await s3
        .upload(params, options)
        .on("httpUploadProgress", function(progress) {
          if (!newFileName.includes("userGenerated")) {
            let progressPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            // console.log(progressPercentage);
            // setProgress(progressPercentage);
            document.getElementsByClassName(
              file.unique
            )[0].innerHTML = `${progressPercentage}%`;
            document.querySelector(
              ".circle" + file.unique + " svg circle"
            ).style["stroke-dasharray"] =
              // Number(
              //   document.querySelector(
              //     ".circle" +
              //       file.name.replace(" ", "_").replace(".", "_") +
              //       file.size +
              //       " svg circle"
              //   ).style["stroke-dasharray"]
              // )
              126.92 + Number(progressPercentage) * 1.27;
            // progressBar.style.width = progressPercentage + "%";
            // if (progressPercentage < 100) {
            // fileUpload.progressStatus = progressPercentage;
            // } else
            secureLocalStorage.setItem("uploading", 1);
            if (progressPercentage == 100) {
              //   doneUploads.push(file.size);
              //   setNewUploadfiles(newUploadFiles.slice(0));
            }
          }
          // fileUpload.progressStatus = progressPercentage;

          // fileUpload.status = "Uploaded";
          // }
        })
        .promise();
      const layerToBeAdded = dateCollected
        ? [
            {
              attributes: {
                description: "",
                color: "",
                fillColor: "",
                strokeColor: "",
                transparency: "",
                strokeTransparency: "",
                fillTransparency: "",
                tags: [],
                links: [],
              },
              dateCollected: dateCollected,
              name: file.name,
              data: {
                url: stored.Location,
                // url: !newFileName.includes(".zip")
                // ? stored.Location
                // : stored.Location.split(".zip")[0] + ".kml",
                size: file.size / 1024 / 1024,
              },
              referenceID: uniqueID,
              status: {
                status:
                  // dataObject.layerType == 4 &&
                  // !file.name.includes(".zip") &&
                  // !file.name.includes(".dxf") &&
                  // !file.name.includes(".json") &&
                  // !file.name.includes(".gpx") &&
                  // !file.name.includes(".geojson")
                  //   ? "Done"
                  //   :
                  dataObject.layerType == 8 ? "Done" : "Processing",
              },
            },
          ]
        : [
            {
              attributes: {
                description: "",
                color: "",
                fillColor: "",
                strokeColor: "",
                transparency: "",
                strokeTransparency: "",
                fillTransparency: "",
                tags: [],
                links: [],
              },
              name: file.name,
              data: {
                url: stored.Location,
                // url: !newFileName.includes(".zip")
                // ? stored.Location
                // : stored.Location.split(".zip")[0] + ".kml",
                size: file.size / 1024 / 1024,
              },
              referenceID: uniqueID,
              status: {
                status:
                  // dataObject.layerType == 4 &&
                  // !file.name.includes(".zip") &&
                  // !file.name.includes(".dxf") &&
                  // !file.name.includes(".json") &&
                  // !file.name.includes(".gpx") &&
                  // !file.name.includes(".geojson")
                  //   ? "Done"
                  //   :
                  dataObject.layerType == 8 ? "Done" : "Processing",
              },
            },
          ];
      const layerResponse = await addNewLayer(
        layerToBeAdded,
        dataObject.projectId,
        dataObject.parentId,
        dataObject.layerType
      );
      if (layerResponse && layerResponse.status === 200) {
        // setProgress(dataObject.progress);
        updateLocalStorage(
          layerResponse?.data?.storageDetail?.storageCapacity,
          layerResponse?.data?.storageDetail?.usedStorage
        );

        if (layerResponse?.data?.layers.length > 0) {
          // *** warning: this will effect drag and drop order
          updateFunction(layerResponse, dataObject, newFileName);
        }
      } else {
        console.log("Testing", "create layer error", layerResponse);
      }
    } catch (error) {
      failedUploads.push(file.unique);
      console.log("Testing", "Catch block s3", error);
      errorDisplay(
        "error",
        error.code == "InvalidToken"
          ? "Upload token is expired, please relogin."
          : `We are facing problem in uploading this ${file.name} file`
      );
    }
  };
  const uploaderFunc = async (newFiles, dateCollected) => {
    let files = [...newFiles];
    let fileSizeCheck = null;
    let imageSizeCheck = null;
    let tempAppState = cloneDeep(appState);

    for (const file of files) {
      let size = file.size / 1024 / 1024;
      if (
        size > JSON.parse(secureLocalStorage.getItem("restrictions"))?.maxUpload
      ) {
        fileSizeCheck = true;
        break;
      }
      if (
        file.name.toLowerCase().includes(".jpeg") ||
        file.name.toLowerCase().includes(".jpg") ||
        file.name.toLowerCase().includes(".png")
      ) {
        let image = await addImageProcess(URL.createObjectURL(file));
        if (
          (image.height * image.width) / 1000000 >
          JSON.parse(secureLocalStorage.getItem("restrictions"))?.imageMax
        ) {
          imageSizeCheck = true;
          break;
        }
      }
    }
    if (iOS()) {
      let testString =
        ".tiff,.tif,.geotif,.geotiff,.kml,.zip,.png,.jpeg,.kmz,.KML,.KMZ,.TIFF,.TIF,.GEOTIF,.GEOTIFF,.las,.LAS,.laz,.LAZ,.jpg,.dxf,.json,.gpx,.DXF,.JSON,.GPX,.geojson,.GEOJSON,.pdf,.txt,.csv,.docx,.xlsx,.doc";
      files = files.filter((fileItem) =>
        testString.includes(fileItem.name.split(".").pop())
      );
    }

    if (imageSizeCheck) {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: (
            <>
              This Image exceeds the MegaPixel limit for your account, You can
              upgrade (
              <Link to={"/upgrade-plan"}>
                {window.location.origin + "/upgrade-plan"}
              </Link>
              ), or contact sales (
              <a href="https://www.metageo.io/contact-us/" target={"_blank"}>
                https://www.metageo.io/contact-us/
              </a>
              ) for a trial.
            </>
          ),
          severity: "error",
          isOpen: true,
          html: true,
        },
      });
      return;
    }
    if (fileSizeCheck) {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: (
            <>
              This file exceeds the tiling limitations for your account, display
              may be unstable, but you can still share and download. You can
              upgrade (
              <Link to={"/upgrade-plan"}>
                {window.location.origin + "/upgrade-plan"}
              </Link>
              ) , or contact sales (
              <a href="https://www.metageo.io/contact-us/" target={"_blank"}>
                https://www.metageo.io/contact-us/
              </a>
              ) for a trial.
            </>
          ),
          severity: "error",
          isOpen: true,
          html: true,
        },
      });
      return;
    }
    let currFolder = allFolders.filter(
      (itemFol) => itemFol.id == tempAppState.folderId
    )[0];
    let duplicate = [];
    files.forEach((file) => {
      let size = (file.size / 1024 / 1024).toFixed(2);
      let filenameRep = file.name.replace(/\s+/g, "_");
      filenameRep = filenameRep.split(".");
      let tempString = "";
      filenameRep.forEach((element, index) => {
        if (index == filenameRep.length - 1) {
          tempString += "." + element;
        } else if (index == filenameRep.length - 2) {
          tempString += element;
        } else {
          tempString += element + "_";
        }
      });
      filenameRep = tempString;
      let name = filenameRep;
      currFolder?.children.forEach((layer) => {
        if (layer.name == name && layer.size.toFixed(2) == size) {
          duplicate.push(layer.name);
        }
      });
    });
    if (duplicate.length) {
      Modal.confirm({
        title:
          "Following duplicate file(s) detected, please select desired action.",
        icon: <ExclamationCircleOutlined />,
        okText: "Duplicate",
        okType: "danger",
        content: (
          <>
            {duplicate.map((itemDup, i) => {
              return duplicate.length - 1 == i ? (
                <strong>{" and " + itemDup}</strong>
              ) : (
                <strong>{" " + itemDup + ","}</strong>
              );
            })}
          </>
        ),
        cancelText: "Skip duplicates",
        onOk: async () => {
          Modal.destroyAll();
          let restrictedFiles = [];
          files.map((file) => {
            const filenameLow = file.name.toLowerCase();
            let filenameRep = file.name.replace(/\s+/g, "_");
            filenameRep = filenameRep.split(".");
            let tempString = "";
            filenameRep.forEach((element, index) => {
              if (index == filenameRep.length - 1) {
                tempString += "." + element;
              } else if (index == filenameRep.length - 2) {
                tempString += element;
              } else {
                tempString += element + "_";
              }
            });
            filenameRep = tempString;
            //const rep = filename.replace(/\s+/g, "_");
            // console.log("filename", filename);
            let format = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;
            let check = format.test(filenameRep);
            console.log("File NAME Check", check);
            if (
              filenameLow.includes(".kmz") ||
              filenameLow.includes(".dxf") ||
              filenameLow.includes(".json") ||
              filenameLow.includes(".gpx") ||
              filenameLow.includes(".geojson") ||
              filenameLow.includes(".tif") ||
              filenameLow.includes(".zip") ||
              filenameLow.includes(".kml") ||
              filenameLow.includes(".png") ||
              filenameLow.includes(".jpeg") ||
              filenameLow.includes(".jpg") ||
              filenameLow.includes(".las") ||
              filenameLow.includes(".laz") ||
              filenameLow.includes(".pdf") ||
              filenameLow.includes(".txt") ||
              filenameLow.includes(".csv") ||
              filenameLow.includes(".docx") ||
              filenameLow.includes(".xlsx") ||
              filenameLow.includes(".doc")
            ) {
              if (!check) {
                const tempFile = new File([file], filenameRep, {
                  type: file.type,
                });
                tempFile["unique"] = uuidv4();
                // console.log("temp", tempFile, "file", file);
                restrictedFiles.push(tempFile);
              }
            }
          });
          let totalSize = 0;
          let availableSize = Number(
            secureLocalStorage.getItem("availableStorage")
          );
          restrictedFiles.map((file) => {
            totalSize = totalSize + file.size / 1024 / 1024;
          });

          if (totalSize < availableSize) {
          } else {
            errorDisplay("error", "You don't have enough storage");
            return;
          }
          let tempArray = restrictedFiles.slice(0);
          if (
            !newUploadFiles.filter(
              (itemFile) =>
                itemFile.group ==
                appState.projects.filter(
                  (itemProj) => itemProj.id == tempAppState.projectId
                )[0]?.name +
                  " > " +
                  allFolders.filter(
                    (itemFol) => itemFol.id == tempAppState.folderId
                  )[0]?.name
            ).length
          ) {
            props.accordianChange1(
              appState.projects.filter(
                (itemProj) => itemProj.id == tempAppState.projectId
              )[0]?.name +
                " > " +
                allFolders.filter(
                  (itemFol) => itemFol.id == tempAppState.folderId
                )[0]?.name
            );
          }
          setNewUploadfiles([
            ...newUploadFiles,
            ...tempArray.map((itemFile) => {
              itemFile["group"] =
                appState.projects.filter(
                  (itemProj) => itemProj.id == tempAppState.projectId
                )[0]?.name +
                " > " +
                allFolders.filter(
                  (itemFol) => itemFol.id == tempAppState.folderId
                )[0]?.name;
              return itemFile;
            }),
          ]);
          if (files.length !== restrictedFiles.length) {
            errorDisplay(
              "error",
              `${files.length -
                restrictedFiles.length} Invalid file(s) name characters or format found`
            );
            if (restrictedFiles.length === 0) {
              document.getElementById("files").value = "";
              return;
            }
          }
          try {
            const dataObject = {
              projectId: tempAppState.projectId,
              parentId: tempAppState.folderId,
              progress: 0,
            };
            for (let i = 0; i < restrictedFiles.length; i++) {
              try {
                let eachUploadedFile = restrictedFiles[i];
                let uniqueID = uuidv4();
                let file = eachUploadedFile;
                let newFileName = `${
                  (eachUploadedFile.name.toLowerCase().includes(".zip") ||
                    eachUploadedFile.name.toLowerCase().includes(".las") ||
                    eachUploadedFile.name.toLowerCase().includes(".laz")) &&
                  secureLocalStorage.getItem("priority")
                    ? `priority${secureLocalStorage.getItem("priority")}/`
                    : ""
                }${secureLocalStorage.getItem(
                  "userId"
                )}/${filePath}/${file.name.replace(/\..+$/, "") +
                  "." +
                  uniqueID}`;
                let bucket = undefined;
                dataObject.progress = ((i + 1) * 100) / restrictedFiles.length;
                // setProgress(dataObject.progress);
                if (eachUploadedFile.name.toLowerCase().includes(".kmz")) {
                  dataObject.layerType = 4;
                  newFileName = `${newFileName}.kmz`;
                  bucket = config.BUCKET_KML;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".pdf") ||
                  eachUploadedFile.name.toLowerCase().includes(".txt") ||
                  eachUploadedFile.name.toLowerCase().includes(".csv") ||
                  eachUploadedFile.name.toLowerCase().includes(".docx") ||
                  eachUploadedFile.name.toLowerCase().includes(".xlsx") ||
                  eachUploadedFile.name.toLowerCase().includes(".doc")
                ) {
                  dataObject.layerType = 8;
                  newFileName =
                    `${newFileName}.` +
                    eachUploadedFile.name.split(".")[
                      eachUploadedFile.name.split(".").length - 1
                    ];
                  newFileName = newFileName.replace(`.${uniqueID}`, "");
                  bucket = config.BUCKET_MISC;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".dxf")
                ) {
                  dataObject.layerType = 4;
                  newFileName = `${newFileName}.dxf`;
                  bucket = config.BUCKET_DXF;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".json")
                ) {
                  dataObject.layerType = 4;
                  newFileName = `${newFileName}.json`;
                  bucket = config.BUCKET_GEO;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".gpx")
                ) {
                  dataObject.layerType = 4;
                  newFileName = `${newFileName}.gpx`;
                  bucket = config.BUCKET_GPX;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".geojson")
                ) {
                  dataObject.layerType = 4;
                  newFileName = `${newFileName}.geojson`;
                  bucket = config.BUCKET_GEO;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".tif")
                ) {
                  dataObject.layerType = 2;
                  // const file1 = await fromBlob(file);
                  // const image = await file1.getImage();
                  // if (
                  //   image?.geoKeys?.GTCitationGeoKey ||
                  //   image?.geoKeys?.GeogCitationGeoKey ||
                  //   image.fileDirectory?.Software?.includes("RealityCapture")
                  // ) {
                  newFileName = `${newFileName}.tiff`;
                  bucket = config.BUCKET_TIFF;
                  // } else {
                  //   errorDisplay(
                  //     "error",
                  //     `We cannot upload the file ${file.name}.Please upload the correct file with valid CRS`
                  //   );
                  //   bucket = undefined;
                  // }
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".zip")
                ) {
                  dataObject.layerType = 4;
                  newFileName = `${newFileName}.zip`;
                  bucket = config.BUCKET_ZIP;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".kml")
                ) {
                  dataObject.layerType = 4;
                  newFileName = `${newFileName}.kml`;
                  bucket = config.BUCKET_KML;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".png")
                ) {
                  dataObject.layerType = 5;
                  newFileName = `${newFileName}.png`;
                  bucket = config.BUCKET_PNG;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".las")
                ) {
                  dataObject.layerType = 6;
                  newFileName = `${newFileName}.las`;
                  bucket = config.BUCKET_LAS;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".laz")
                ) {
                  dataObject.layerType = 6;
                  newFileName = `${newFileName}.laz`;
                  bucket = config.BUCKET_LAS;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".jpeg") ||
                  eachUploadedFile.name.toLowerCase().includes(".jpg")
                ) {
                  dataObject.layerType = 5;
                  bucket = config.BUCKET_JPG;
                  if (eachUploadedFile.name.toLowerCase().includes(".jpeg")) {
                    newFileName = `${newFileName}.jpeg`;
                  } else if (
                    eachUploadedFile.name.toLowerCase().includes(".jpg")
                  ) {
                    newFileName = `${newFileName}.jpg`;
                  }
                } else {
                  errorDisplay(
                    "error",
                    `We cannot upload the file ${file.name}.Please upload the correct file`
                  );
                }

                if (bucket) {
                  await uploadFile(
                    bucket,
                    uniqueID,
                    newFileName,
                    file,
                    dataObject,
                    dateCollected
                  );
                  if (!failedUploads.includes(file.unique)) {
                    doneUploads.push(file.unique);
                  }
                  stateChecker();
                  setTimeout(() => {
                    setCheck(!check1);
                  }, 1);
                  if (i + 1 == restrictedFiles.length) {
                    if (
                      newUploadFiles.filter(
                        (itemFile) =>
                          doneUploads.includes(itemFile.unique) ||
                          failedUploads.includes(itemFile.unique)
                      ).length == newUploadFiles.length
                    )
                      secureLocalStorage.removeItem("uploading");
                  }
                }
              } catch (error) {
                console.log("Testing", "Catch block of structure file", error);
              }
            }
          } catch (e) {
            console.log(e);
          }
        },
        onCancel: async () => {
          Modal.destroyAll();
          let restrictedFiles = [];
          files = files.filter((file) => {
            let duplicateSkip = false;
            let size = (file.size / 1024 / 1024).toFixed(2);
            let filenameRep = file.name.replace(/\s+/g, "_");
            filenameRep = filenameRep.split(".");
            let tempString = "";
            filenameRep.forEach((element, index) => {
              if (index == filenameRep.length - 1) {
                tempString += "." + element;
              } else if (index == filenameRep.length - 2) {
                tempString += element;
              } else {
                tempString += element + "_";
              }
            });
            filenameRep = tempString;
            let name = filenameRep;
            currFolder?.children.forEach((layer) => {
              if (layer.name == name && layer.size.toFixed(2) == size) {
                duplicateSkip = true;
              }
            });
            if (!duplicateSkip) {
              return file;
            } else {
              return false;
            }
          });
          files.map((file) => {
            const filenameLow = file.name.toLowerCase();
            let filenameRep = file.name.replace(/\s+/g, "_");
            filenameRep = filenameRep.split(".");
            let tempString = "";
            filenameRep.forEach((element, index) => {
              if (index == filenameRep.length - 1) {
                tempString += "." + element;
              } else if (index == filenameRep.length - 2) {
                tempString += element;
              } else {
                tempString += element + "_";
              }
            });
            filenameRep = tempString;
            //const rep = filename.replace(/\s+/g, "_");
            // console.log("filename", filename);
            let format = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;
            let check = format.test(filenameRep);
            console.log("File NAME Check", check);
            if (
              filenameLow.includes(".kmz") ||
              filenameLow.includes(".dxf") ||
              filenameLow.includes(".json") ||
              filenameLow.includes(".gpx") ||
              filenameLow.includes(".geojson") ||
              filenameLow.includes(".tif") ||
              filenameLow.includes(".zip") ||
              filenameLow.includes(".kml") ||
              filenameLow.includes(".png") ||
              filenameLow.includes(".jpeg") ||
              filenameLow.includes(".jpg") ||
              filenameLow.includes(".las") ||
              filenameLow.includes(".laz") ||
              filenameLow.includes(".pdf") ||
              filenameLow.includes(".txt") ||
              filenameLow.includes(".csv") ||
              filenameLow.includes(".docx") ||
              filenameLow.includes(".xlsx") ||
              filenameLow.includes(".doc")
            ) {
              if (!check) {
                const tempFile = new File([file], filenameRep, {
                  type: file.type,
                });
                tempFile["unique"] = uuidv4();
                // console.log("temp", tempFile, "file", file);
                restrictedFiles.push(tempFile);
              }
            }
          });
          let totalSize = 0;
          let availableSize = Number(
            secureLocalStorage.getItem("availableStorage")
          );
          restrictedFiles.map((file) => {
            totalSize = totalSize + file.size / 1024 / 1024;
          });

          if (totalSize < availableSize) {
          } else {
            errorDisplay("error", "You don't have enough storage");
            return;
          }
          let tempArray = restrictedFiles.slice(0);
          if (
            !newUploadFiles.filter(
              (itemFile) =>
                itemFile.group ==
                appState.projects.filter(
                  (itemProj) => itemProj.id == tempAppState.projectId
                )[0]?.name +
                  " > " +
                  allFolders.filter(
                    (itemFol) => itemFol.id == tempAppState.folderId
                  )[0]?.name
            ).length
          ) {
            props.accordianChange1(
              appState.projects.filter(
                (itemProj) => itemProj.id == tempAppState.projectId
              )[0]?.name +
                " > " +
                allFolders.filter(
                  (itemFol) => itemFol.id == tempAppState.folderId
                )[0]?.name
            );
          }
          setNewUploadfiles([
            ...newUploadFiles,
            ...tempArray.map((itemFile) => {
              itemFile["group"] =
                appState.projects.filter(
                  (itemProj) => itemProj.id == tempAppState.projectId
                )[0]?.name +
                " > " +
                allFolders.filter(
                  (itemFol) => itemFol.id == tempAppState.folderId
                )[0]?.name;
              return itemFile;
            }),
          ]);
          if (files.length !== restrictedFiles.length) {
            errorDisplay(
              "error",
              `${files.length -
                restrictedFiles.length} Invalid file(s) name characters or format found`
            );
            if (restrictedFiles.length === 0) {
              document.getElementById("files").value = "";
              return;
            }
          }
          try {
            const dataObject = {
              projectId: tempAppState.projectId,
              parentId: tempAppState.folderId,
              progress: 0,
            };
            for (let i = 0; i < restrictedFiles.length; i++) {
              try {
                let eachUploadedFile = restrictedFiles[i];
                let uniqueID = uuidv4();
                let file = eachUploadedFile;
                let newFileName = `${
                  (eachUploadedFile.name.toLowerCase().includes(".zip") ||
                    eachUploadedFile.name.toLowerCase().includes(".las") ||
                    eachUploadedFile.name.toLowerCase().includes(".laz")) &&
                  secureLocalStorage.getItem("priority")
                    ? `priority${secureLocalStorage.getItem("priority")}/`
                    : ""
                }${secureLocalStorage.getItem(
                  "userId"
                )}/${filePath}/${file.name.replace(/\..+$/, "") +
                  "." +
                  uniqueID}`;
                let bucket = undefined;
                dataObject.progress = ((i + 1) * 100) / restrictedFiles.length;
                // setProgress(dataObject.progress);
                if (eachUploadedFile.name.toLowerCase().includes(".kmz")) {
                  dataObject.layerType = 4;
                  newFileName = `${newFileName}.kmz`;
                  bucket = config.BUCKET_KML;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".pdf") ||
                  eachUploadedFile.name.toLowerCase().includes(".txt") ||
                  eachUploadedFile.name.toLowerCase().includes(".csv") ||
                  eachUploadedFile.name.toLowerCase().includes(".docx") ||
                  eachUploadedFile.name.toLowerCase().includes(".xlsx") ||
                  eachUploadedFile.name.toLowerCase().includes(".doc")
                ) {
                  dataObject.layerType = 8;
                  newFileName =
                    `${newFileName}.` +
                    eachUploadedFile.name.split(".")[
                      eachUploadedFile.name.split(".").length - 1
                    ];
                  newFileName = newFileName.replace(`.${uniqueID}`, "");
                  bucket = config.BUCKET_MISC;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".dxf")
                ) {
                  dataObject.layerType = 4;
                  newFileName = `${newFileName}.dxf`;
                  bucket = config.BUCKET_DXF;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".json")
                ) {
                  dataObject.layerType = 4;
                  newFileName = `${newFileName}.json`;
                  bucket = config.BUCKET_GEO;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".gpx")
                ) {
                  dataObject.layerType = 4;
                  newFileName = `${newFileName}.gpx`;
                  bucket = config.BUCKET_GPX;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".geojson")
                ) {
                  dataObject.layerType = 4;
                  newFileName = `${newFileName}.geojson`;
                  bucket = config.BUCKET_GEO;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".tif")
                ) {
                  dataObject.layerType = 2;
                  // const file1 = await fromBlob(file);
                  // const image = await file1.getImage();
                  // if (
                  //   image?.geoKeys?.GTCitationGeoKey ||
                  //   image?.geoKeys?.GeogCitationGeoKey ||
                  //   image.fileDirectory?.Software?.includes("RealityCapture")
                  // ) {
                  newFileName = `${newFileName}.tiff`;
                  bucket = config.BUCKET_TIFF;
                  // } else {
                  //   errorDisplay(
                  //     "error",
                  //     `We cannot upload the file ${file.name}.Please upload the correct file with valid CRS`
                  //   );
                  //   bucket = undefined;
                  // }
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".zip")
                ) {
                  dataObject.layerType = 4;
                  newFileName = `${newFileName}.zip`;
                  bucket = config.BUCKET_ZIP;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".kml")
                ) {
                  dataObject.layerType = 4;
                  newFileName = `${newFileName}.kml`;
                  bucket = config.BUCKET_KML;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".png")
                ) {
                  dataObject.layerType = 5;
                  newFileName = `${newFileName}.png`;
                  bucket = config.BUCKET_PNG;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".las")
                ) {
                  dataObject.layerType = 6;
                  newFileName = `${newFileName}.las`;
                  bucket = config.BUCKET_LAS;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".laz")
                ) {
                  dataObject.layerType = 6;
                  newFileName = `${newFileName}.laz`;
                  bucket = config.BUCKET_LAS;
                } else if (
                  eachUploadedFile.name.toLowerCase().includes(".jpeg") ||
                  eachUploadedFile.name.toLowerCase().includes(".jpg")
                ) {
                  dataObject.layerType = 5;
                  bucket = config.BUCKET_JPG;
                  if (eachUploadedFile.name.toLowerCase().includes(".jpeg")) {
                    newFileName = `${newFileName}.jpeg`;
                  } else if (
                    eachUploadedFile.name.toLowerCase().includes(".jpg")
                  ) {
                    newFileName = `${newFileName}.jpg`;
                  }
                } else {
                  errorDisplay(
                    "error",
                    `We cannot upload the file ${file.name}.Please upload the correct file`
                  );
                }

                if (bucket) {
                  await uploadFile(
                    bucket,
                    uniqueID,
                    newFileName,
                    file,
                    dataObject,
                    dateCollected
                  );
                  if (!failedUploads.includes(file.unique)) {
                    doneUploads.push(file.unique);
                  }
                  stateChecker();
                  setTimeout(() => {
                    setCheck(!check1);
                  }, 1);
                  if (i + 1 == restrictedFiles.length) {
                    if (
                      newUploadFiles.filter(
                        (itemFile) =>
                          doneUploads.includes(itemFile.unique) ||
                          failedUploads.includes(itemFile.unique)
                      ).length == newUploadFiles.length
                    )
                      secureLocalStorage.removeItem("uploading");
                  }
                }
              } catch (error) {
                console.log("Testing", "Catch block of structure file", error);
              }
            }
          } catch (e) {
            console.log(e);
          }
        },
      });
      return;
    }
    let restrictedFiles = [];
    files.map((file) => {
      const filenameLow = file.name.toLowerCase();
      let filenameRep = file.name.replace(/\s+/g, "_");
      filenameRep = filenameRep.split(".");
      let tempString = "";
      filenameRep.forEach((element, index) => {
        if (index == filenameRep.length - 1) {
          tempString += "." + element;
        } else if (index == filenameRep.length - 2) {
          tempString += element;
        } else {
          tempString += element + "_";
        }
      });
      filenameRep = tempString;
      //const rep = filename.replace(/\s+/g, "_");
      // console.log("filename", filename);
      let format = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;
      let check = format.test(filenameRep);
      console.log("File NAME Check", check);
      if (
        filenameLow.includes(".kmz") ||
        filenameLow.includes(".dxf") ||
        filenameLow.includes(".json") ||
        filenameLow.includes(".gpx") ||
        filenameLow.includes(".geojson") ||
        filenameLow.includes(".tif") ||
        filenameLow.includes(".zip") ||
        filenameLow.includes(".kml") ||
        filenameLow.includes(".png") ||
        filenameLow.includes(".jpeg") ||
        filenameLow.includes(".jpg") ||
        filenameLow.includes(".las") ||
        filenameLow.includes(".laz") ||
        filenameLow.includes(".pdf") ||
        filenameLow.includes(".txt") ||
        filenameLow.includes(".csv") ||
        filenameLow.includes(".docx") ||
        filenameLow.includes(".xlsx") ||
        filenameLow.includes(".doc")
      ) {
        if (!check) {
          const tempFile = new File([file], filenameRep, {
            type: file.type,
          });
          tempFile["unique"] = uuidv4();
          // console.log("temp", tempFile, "file", file);
          restrictedFiles.push(tempFile);
        }
      }
    });
    let totalSize = 0;
    let availableSize = Number(secureLocalStorage.getItem("availableStorage"));
    restrictedFiles.map((file) => {
      totalSize = totalSize + file.size / 1024 / 1024;
    });

    if (totalSize < availableSize) {
    } else {
      errorDisplay("error", "You don't have enough storage");
      return;
    }
    let tempArray = restrictedFiles.slice(0);
    if (
      !newUploadFiles.filter(
        (itemFile) =>
          itemFile.group ==
          appState.projects.filter(
            (itemProj) => itemProj.id == tempAppState.projectId
          )[0]?.name +
            " > " +
            allFolders.filter(
              (itemFol) => itemFol.id == tempAppState.folderId
            )[0]?.name
      ).length
    ) {
      props.accordianChange1(
        appState.projects.filter(
          (itemProj) => itemProj.id == tempAppState.projectId
        )[0]?.name +
          " > " +
          allFolders.filter((itemFol) => itemFol.id == tempAppState.folderId)[0]
            ?.name
      );
    }
    setNewUploadfiles([
      ...newUploadFiles,
      ...tempArray.map((itemFile) => {
        itemFile["group"] =
          appState.projects.filter(
            (itemProj) => itemProj.id == tempAppState.projectId
          )[0]?.name +
          " > " +
          allFolders.filter((itemFol) => itemFol.id == tempAppState.folderId)[0]
            ?.name;
        return itemFile;
      }),
    ]);
    if (files.length !== restrictedFiles.length) {
      errorDisplay(
        "error",
        `${files.length -
          restrictedFiles.length} Invalid file(s) name characters or format found`
      );
      if (restrictedFiles.length === 0) {
        document.getElementById("files").value = "";
        return;
      }
    }
    try {
      const dataObject = {
        projectId: tempAppState.projectId,
        parentId: tempAppState.folderId,
        progress: 0,
      };
      for (let i = 0; i < restrictedFiles.length; i++) {
        try {
          let eachUploadedFile = restrictedFiles[i];
          let uniqueID = uuidv4();
          let file = eachUploadedFile;
          let newFileName = `${
            (eachUploadedFile.name.toLowerCase().includes(".zip") ||
              eachUploadedFile.name.toLowerCase().includes(".las") ||
              eachUploadedFile.name.toLowerCase().includes(".laz")) &&
            secureLocalStorage.getItem("priority")
              ? `priority${secureLocalStorage.getItem("priority")}/`
              : ""
          }${secureLocalStorage.getItem(
            "userId"
          )}/${filePath}/${file.name.replace(/\..+$/, "") + "." + uniqueID}`;
          let bucket = undefined;
          dataObject.progress = ((i + 1) * 100) / restrictedFiles.length;
          // setProgress(dataObject.progress);
          if (eachUploadedFile.name.toLowerCase().includes(".kmz")) {
            dataObject.layerType = 4;
            newFileName = `${newFileName}.kmz`;
            bucket = config.BUCKET_KML;
          } else if (
            eachUploadedFile.name.toLowerCase().includes(".pdf") ||
            eachUploadedFile.name.toLowerCase().includes(".txt") ||
            eachUploadedFile.name.toLowerCase().includes(".csv") ||
            eachUploadedFile.name.toLowerCase().includes(".docx") ||
            eachUploadedFile.name.toLowerCase().includes(".xlsx") ||
            eachUploadedFile.name.toLowerCase().includes(".doc")
          ) {
            dataObject.layerType = 8;
            newFileName =
              `${newFileName}.` +
              eachUploadedFile.name.split(".")[
                eachUploadedFile.name.split(".").length - 1
              ];
            newFileName = newFileName.replace(`.${uniqueID}`, "");
            bucket = config.BUCKET_MISC;
          } else if (eachUploadedFile.name.toLowerCase().includes(".dxf")) {
            dataObject.layerType = 4;
            newFileName = `${newFileName}.dxf`;
            bucket = config.BUCKET_DXF;
          } else if (eachUploadedFile.name.toLowerCase().includes(".json")) {
            dataObject.layerType = 4;
            newFileName = `${newFileName}.json`;
            bucket = config.BUCKET_GEO;
          } else if (eachUploadedFile.name.toLowerCase().includes(".gpx")) {
            dataObject.layerType = 4;
            newFileName = `${newFileName}.gpx`;
            bucket = config.BUCKET_GPX;
          } else if (eachUploadedFile.name.toLowerCase().includes(".geojson")) {
            dataObject.layerType = 4;
            newFileName = `${newFileName}.geojson`;
            bucket = config.BUCKET_GEO;
          } else if (eachUploadedFile.name.toLowerCase().includes(".tif")) {
            dataObject.layerType = 2;
            // const file1 = await fromBlob(file);
            // const image = await file1.getImage();
            // if (
            //   image?.geoKeys?.GTCitationGeoKey ||
            //   image?.geoKeys?.GeogCitationGeoKey ||
            //   image.fileDirectory?.Software?.includes("RealityCapture")
            // ) {
            newFileName = `${newFileName}.tiff`;
            bucket = config.BUCKET_TIFF;
            // } else {
            //   errorDisplay(
            //     "error",
            //     `We cannot upload the file ${file.name}.Please upload the correct file with valid CRS`
            //   );
            //   bucket = undefined;
            // }
          } else if (eachUploadedFile.name.toLowerCase().includes(".zip")) {
            dataObject.layerType = 4;
            newFileName = `${newFileName}.zip`;
            bucket = config.BUCKET_ZIP;
          } else if (eachUploadedFile.name.toLowerCase().includes(".kml")) {
            dataObject.layerType = 4;
            newFileName = `${newFileName}.kml`;
            bucket = config.BUCKET_KML;
          } else if (eachUploadedFile.name.toLowerCase().includes(".png")) {
            dataObject.layerType = 5;
            newFileName = `${newFileName}.png`;
            bucket = config.BUCKET_PNG;
          } else if (eachUploadedFile.name.toLowerCase().includes(".las")) {
            dataObject.layerType = 6;
            newFileName = `${newFileName}.las`;
            bucket = config.BUCKET_LAS;
          } else if (eachUploadedFile.name.toLowerCase().includes(".laz")) {
            dataObject.layerType = 6;
            newFileName = `${newFileName}.laz`;
            bucket = config.BUCKET_LAS;
          } else if (
            eachUploadedFile.name.toLowerCase().includes(".jpeg") ||
            eachUploadedFile.name.toLowerCase().includes(".jpg")
          ) {
            dataObject.layerType = 5;
            bucket = config.BUCKET_JPG;
            if (eachUploadedFile.name.toLowerCase().includes(".jpeg")) {
              newFileName = `${newFileName}.jpeg`;
            } else if (eachUploadedFile.name.toLowerCase().includes(".jpg")) {
              newFileName = `${newFileName}.jpg`;
            }
          } else {
            errorDisplay(
              "error",
              `We cannot upload the file ${file.name}.Please upload the correct file`
            );
          }

          if (bucket) {
            await uploadFile(
              bucket,
              uniqueID,
              newFileName,
              file,
              dataObject,
              dateCollected
            );
            if (!failedUploads.includes(file.unique)) {
              doneUploads.push(file.unique);
            }
            stateChecker();
            setTimeout(() => {
              setCheck(!check1);
            }, 1);
            if (i + 1 == restrictedFiles.length) {
              if (
                newUploadFiles.filter(
                  (itemFile) =>
                    doneUploads.includes(itemFile.unique) ||
                    failedUploads.includes(itemFile.unique)
                ).length == newUploadFiles.length
              )
                secureLocalStorage.removeItem("uploading");
            }
          }
        } catch (error) {
          console.log("Testing", "Catch block of structure file", error);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (wizardUploaderFiles) {
      uploaderFunc(wizardUploaderFiles, dateCollectedWizard);
      setWizardUploaderFiles(null);
    }
  }, [wizardUploaderFiles]);
  return (
    <span className="layerBox">
      <Button
        className="infoClose"
        style={{
          fontSize: 13,
          fontWeight: 600,
          color: "gray",
        }}
        onClick={props.backLayerPanel}
      >
        <ChevronLeft /> Layer Panel
      </Button>
      <Box className="TitleSection">
        {appState.projectId ? (
          <h3>
            Uploading in{" "}
            {appState.projects.filter(
              (itemProj) => itemProj.id == appState.projectId
            )[0]?.name +
              " / " +
              `${
                allFolders.filter((itemFol) => itemFol.id == appState.folderId)
                  .length
                  ? allFolders.filter(
                      (itemFol) => itemFol.id == appState.folderId
                    )[0]?.name
                  : ""
              }`}
          </h3>
        ) : (
          "Uploads"
        )}
      </Box>
      {appState.projectId ? (
        <h4>
          Uploading in{" "}
          {appState.projects.filter(
            (itemProj) => itemProj.id == appState.projectId
          )[0]?.name +
            " / " +
            `${
              allFolders.filter((itemFol) => itemFol.id == appState.folderId)
                .length
                ? allFolders.filter(
                    (itemFol) => itemFol.id == appState.folderId
                  )[0]?.name
                : ""
            }`}
        </h4>
      ) : (
        ""
      )}
      <input
        hidden={!appState.projectId || !appState.folderId}
        type="file"
        id="files"
        title=""
        className="custom-file-input"
        name="files"
        accept={
          iOS()
            ? ""
            : ".tiff,.tif,.geotif,.geotiff,.kml,.zip,.png,.jpeg,.kmz,.KML,.KMZ,.TIFF,.TIF,.GEOTIF,.GEOTIFF,.las,.LAS,.laz,.LAZ,.jpg,.dxf,.json,.gpx,.DXF,.JSON,.GPX,.geojson,.GEOJSON,.pdf,.txt,.csv,.docx,.xlsx,.doc"
        }
        multiple
        onChange={async (event) => {
          uploaderFunc(event.target.files, null);
        }}
      ></input>
      <Box sx={{ mt: 2 }} className="TitleSection">
        <h5>
          Click here to check{" "}
          <a
            href="https://support.metageo.io/support/solutions/articles/150000084137-what-file-types-does-metageo-support-"
            target={"_blank"}
          >
            Supported FIles
          </a>
        </h5>
      </Box>

      <Button
        disabled={
          newUploadFiles.filter(
            (itemFile) =>
              doneUploads.includes(itemFile.unique) ||
              failedUploads.includes(itemFile.unique)
          ).length
            ? false
            : true
        }
        hidden={!newUploadFiles.length}
        variant="outlined"
        style={{
          textTransform: "none",
          display: "flex",
          margin: "auto !important",
          paddingInline: "10px",
        }}
        onClick={() => {
          setNewUploadfiles(
            newUploadFiles.filter(
              (itemFile) =>
                !doneUploads.includes(itemFile.unique) &&
                !failedUploads.includes(itemFile.unique)
            )
          );
        }}
      >
        Clear completed uploads
        <BackspaceIcon
          style={{
            marginLeft: "6px",
            height: 18,
            width: "auto",
            cursor: "pointer",
          }}
        />
      </Button>
      <List
        className={check1 ? "check" : ""}
        style={{ width: "100%", bgcolor: "background.paper" }}
      >
        {Object.keys(groupBy(newUploadFiles, "group")).map((itemAccordian) => {
          return (
            <Accordion
              defaultExpanded={props.openAccordiansUploader.includes(
                itemAccordian
              )}
              onChange={() => {
                props.accordianChange(itemAccordian);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <span style={{ fontSize: 14, fontWeight: 700, padding: 10 }}>
                  {itemAccordian +
                    ` ${
                      groupBy(newUploadFiles, "group")[
                        itemAccordian
                      ].filter((x) => doneUploads.includes(x.unique)).length
                    }/${
                      groupBy(newUploadFiles, "group")[itemAccordian].length
                    }`}
                </span>
              </AccordionSummary>
              <AccordionDetails>
                {groupBy(newUploadFiles, "group")[itemAccordian].map(
                  (item, index) => (
                    <>
                      <ListItem alignItems="center">
                        <ListItemAvatar>
                          <DescriptionIcon
                            style={{
                              color: "blue",
                              height: 27,
                              width: "auto",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          className="uploadName"
                          primary={`${item.name.slice(0, 20)}${
                            item.name.length > 20 ? "..." : ""
                          }`}
                        />
                        {doneUploads.includes(item.unique) ? (
                          <>
                            <CheckCircleIcon
                              style={{
                                height: 19,
                                width: "auto",
                                color: "#4BB543",
                              }}
                            />
                            {/* <BackspaceIcon
                                onClick={() => {
                                  setNewUploadfiles(
                                    newUploadFiles.filter(
                                      (itemFile) =>
                                        itemFile.unique !== item.unique
                                    )
                                  );
                                }}
                                style={{
                                  height: 19,
                                  width: "auto",
                                  cursor: "pointer",
                                }}
                              /> */}
                          </>
                        ) : failedUploads.includes(item.unique) ? (
                          <CancelIcon
                            style={{
                              height: 19,
                              width: "auto",
                              color: "red",
                            }}
                          />
                        ) : (
                          <CircularProgressWithLabel
                            className={item.unique}
                            value={0}
                          />
                        )}
                      </ListItem>
                      <Divider component="li" />
                    </>
                  )
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </List>
    </span>
  );
};

export default MetaUploader;
